const baseUrl = 'https://api.ytb300.com/mall'// api请求
const imgUrl = 'https://300-hongkong.oss-cn-hongkong.aliyuncs.com/' // 图片域名
// 语言转换
const lang = {
  null: "english",
  English: "english",
  日本語: "japanese",
  Português: "portuguese",
  繁體中文: "chineseTraditional",
  Français: "french",
  Deutsch: "german",
  Türk: "turkish",
  Español: "spanish",
  عربي: "arabic",
  한국인: 'korean',
  Suomi: 'finland',
  Русский: 'russian',
  italiano: 'italian',
  แบบไทย: 'thai',
  Nederlands: 'dutch',
}


export default {
  imgUrl,
  baseUrl,
  lang,

}
