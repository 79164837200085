<template>
  <div class="appMerchant-settlement-warper">
    <appHeader
      :navTitle="language.navTitle"
      :isShow="navIconIsShow"
      :rightIcon="true"
    >
      <template #navRight>
        <languageCard />
      </template>
    </appHeader>
    <div class="merchant-body">
      <div class="body-field-boxers">
        <div class="field-row">
          <div class="line-label">
            <span class="label">{{ language.label1 }}</span>
            <van-popover
              trigger="click"
              v-model="popoverIsShow1"
              :actions="[{ text: language.label1Hint }]"
              @select="popoverIsShow1 = !popoverIsShow1"
              :theme="'dark'"
              placement="right"
            >
              <template #reference>
                <van-icon name="warning" />
              </template>
            </van-popover>
          </div>
          <van-field
            v-model="formObj.name"
            :placeholder="language.visible1"
            clearable
          />
        </div>
        <div class="field-row">
          <div class="line-label">
            <span class="label">{{ language.label2 }}</span>
            <van-popover
              trigger="click"
              className="popover-class2"
              v-model="popoverIsShow2"
              placement="right"
              :actions="[{ text: language.label2Hint }]"
              @select="popoverIsShow2 = !popoverIsShow2"
              :theme="'dark'"
            >
              <template #reference>
                <van-icon name="warning" />
              </template>
            </van-popover>
          </div>
          <van-field
            v-model="formObj.email"
            :placeholder="language.visible2"
            clearable
          />
        </div>
        <div class="field-row">
          <div class="line-label">
            <span class="label">{{ language.label3 }}</span>
            <van-popover
              trigger="click"
              v-model="popoverIsShow3"
              :actions="[{ text: language.label3Hint }]"
              @select="popoverIsShow3 = !popoverIsShow3"
              placement="right"
              :theme="'dark'"
            >
              <template #reference>
                <van-icon name="warning" />
              </template>
            </van-popover>
          </div>
          <van-field
            v-model="formObj.verificationCode"
            :placeholder="language.visible3"
            clearable
            class="email-field"
          >
            <template #button>
              <van-button
                class="send-btn"
                size="small"
                :disabled="timer < 60"
                @click="sendChange"
              >
                {{ sendOptions }}
              </van-button>
            </template>
          </van-field>
        </div>
        <div class="field-row">
          <div class="line-label">
            <span class="label">{{ language.label4 }}</span>
            <van-popover
              trigger="click"
              className="popover-class2"
              v-model="popoverIsShow4"
              placement="right"
              :actions="[{ text: language.label4Hint }]"
              @select="popoverIsShow4 = !popoverIsShow4"
              :theme="'dark'"
            >
              <template #reference>
                <van-icon name="warning" />
              </template>
            </van-popover>
          </div>
          <van-field
            type="password"
            v-model="formObj.password"
            :placeholder="language.visible4"
            clearable
          />
        </div>
        <div class="field-row">
          <div class="line-label">
            <span class="label">{{ language.label5 }}</span>
            <van-popover
              trigger="click"
              className="popover-class2"
              v-model="popoverIsShow5"
              placement="right"
              :actions="[{ text: language.label5Hint }]"
              @select="popoverIsShow5 = !popoverIsShow5"
              :theme="'dark'"
            >
              <template #reference>
                <van-icon name="warning" />
              </template>
            </van-popover>
          </div>
          <van-field
            v-model="formObj.invitationCode"
            :placeholder="language.visible5"
            clearable
          />
        </div>
        <div class="field-row upload-row">
          <div class="line-label">
            <span class="label">{{ language.label6 }}</span>
            <van-popover
              trigger="click"
              className="popover-class2"
              v-model="popoverIsShow6"
              placement="right"
              :actions="[{ text: language.label6Hint }]"
              @select="popoverIsShow6 = !popoverIsShow6"
              :theme="'dark'"
            >
              <template #reference>
                <van-icon name="warning" />
              </template>
            </van-popover>
          </div>
          <div class="uploader-boxers phone">
            <div class="upload-field">
              <van-uploader :after-read="loginImageUpload">
                <div
                  class="images-load imgFlex"
                  v-if="formObj.logoImage"
                  :before-read="beforeRead"
                >
                  <img v-lazy="getUrlImg(formObj.logoImage)" class="avatar" />
                </div>
                <van-icon name="add-o" v-else />
              </van-uploader>
            </div>
          </div>
        </div>
        <div class="field-row upload-row">
          <div class="line-label">
            <span class="label">{{ language.label7 }}</span>
            <van-popover
              trigger="click"
              className="popover-class2"
              v-model="popoverIsShow7"
              placement="right"
              :actions="[{ text: language.label7Hint }]"
              @select="popoverIsShow7 = !popoverIsShow7"
              :theme="'dark'"
            >
              <template #reference>
                <van-icon name="warning" />
              </template>
            </van-popover>
          </div>
          <div class="uploader-boxers phone">
            <div class="upload-field">
              <van-uploader
                :after-read="firstImageUpload"
                :before-read="beforeRead"
              >
                <div class="images-load imgFlex" v-if="formObj.firstImage">
                  <img v-lazy="formObj.firstImage" class="avatar" />
                </div>
                <van-icon name="add-o" v-else />
              </van-uploader>
            </div>
            <div class="upload-field">
              <van-uploader
                :after-read="lastImageUpload"
                :before-read="beforeRead"
              >
                <div class="images-load imgFlex" v-if="formObj.lastImage">
                  <img v-lazy="formObj.lastImage" class="avatar" />
                </div>
                <van-icon name="add-o" v-else />
              </van-uploader>
            </div>
          </div>
        </div>
        <div class="body-filed-like">
          <div class="like-check">
            <van-checkbox
              v-model="checked"
              @change="onCheckChange"
              shape="square"
            >
            </van-checkbox>
          </div>
          <div class="like-text">
            <div class="like-item" @click="onChangeDoc('clause')">
              <p>{{ language.optionBtn1 }}</p>
              <p class="line"></p>
            </div>
            <div class="like-item" @click="onChangeDoc('policy')">
              <p>{{ language.optionBtn2 }}</p>
              <p class="line"></p>
            </div>
          </div>
        </div>
        <div class="body-field-options">
          <van-button
            class="affirm-btn"
            :disabled="!checked"
            @click="onChangeSubmit"
            >{{ language.optionBtn3 }}</van-button
          >
        </div>
      </div>
    </div>
    <van-popup
      v-if="policyIsShow"
      v-model="policyIsShow"
      @close="policyIsShow = !policyIsShow"
      position="bottom"
      class="popup-policy"
    >
      <div class="popup-title">{{ popupTitle }}</div>
      <div class="policy-popup-body" v-html="policyHtml"></div>
    </van-popup>
  </div>
</template>

<script>
import { getParams } from "@/utils/publicMethods";
import appHeader from "../component/app-header.vue";
import appMerchantApi from "@/api/h5Api/appMerchantApi.js";
import imgApi from "@/utils/projectConfig.js";
let Base64 = require("js-base64").Base64;
import languageCard from "@/layout/language";
export default {
  name: "appMerchantSettlement",
  data() {
    return {
      language: this.$language(),
      imageApi: imgApi.imgUrl,
      popoverIsShow1: false,
      popoverIsShow2: false,
      popoverIsShow3: false,
      popoverIsShow4: false,
      popoverIsShow5: false,
      popoverIsShow6: false,
      popoverIsShow7: false,
      value: "",
      timer: 60,
      sendCodeSetTimer: "",
      sendOptions: "",
      formObj: {
        name: "",
        password: "",
        email: "",
        verificationCode: "",
        invitationCode: "",
        logoImage: "",
        firstImage: "",
        lastImage: "",
      },
      checked: false,
      policyHtml: "",
      policyIsShow: false,
      popupTitle: "",
      base64Str: "",
      navIconIsShow: true,
    };
  },
  components: {
    appHeader,
    languageCard,
  },
  created() {
    this.base64Str = getParams("base64");
    if (this.base64Str) {
      this.navIconIsShow = false;
      let decodedStr = Base64.decode(decodeURIComponent(this.base64Str));
      let decodedStrObj = JSON.parse(decodedStr);
      if (Object.keys(decodedStrObj).length > 0) {
        this.formObj.logoImage = decodeURIComponent(decodedStrObj.avatar);
        this.formObj.name = decodeURIComponent(decodedStrObj.nickname);
      }
    }
    const code = getParams("code") || localStorage.getItem("code");
    if (code == 0 || code) {
      localStorage.setItem("code", code);
      this.formObj.invitationCode = code;
    }
  },
  mounted() {
    this.sendOptions = this.language.sendEmail;
    if (
      sessionStorage.getItem("CODE") !== null &&
      sessionStorage.getItem("CODE") !== "undefined"
    ) {
      this.formObj.invitationCode = sessionStorage.getItem("CODE");
    }
  },
  methods: {
    async onChangeDoc(key) {
      let params;
      if (key == "clause") {
        this.popupTitle = this.language.popupTitle1;
        params = "policyCenterTermsOfService";
      }
      if (key == "policy") {
        this.popupTitle = this.language.popupTitle2;
        params = "supportSellerProtection";
      }
      let { code, data } = await appMerchantApi.getConfig_content({
        contentType: params,
      });
      if (code == 200) {
        this.policyHtml = data.content;
        this.policyIsShow = true;
      }
    },
    async onChangeSubmit() {
      let params = {
        name: this.formObj.name,
        account: this.formObj.email,
        verificationCode: this.formObj.verificationCode,
        password: this.formObj.password,
        certFrontImage: this.formObj.firstIcon,
        image: this.formObj.logoImage,
        inviteCode: this.formObj.invitationCode,
        certReverseImage: this.formObj.lastIcon,
        tiktokMember: this.base64Str ? decodeURIComponent(this.base64Str) : "",
      };
      if (!params.tiktokMember) {
        delete params.tiktokMember;
      }
      const { code, message } = await appMerchantApi.shop_application(params);
      if (code == 200) {
        this.$toast.success(message);
        if (!this.base64Str) {
          this.$router.go("-1");
        }
      }
    },
    onCheckChange(e) {
      this.checked = e;
    },
    loginImageUpload(file) {
      this.afterRead(file, "loginImageUpload");
    },
    firstImageUpload(file) {
      this.afterRead(file, "firstImageUpload");
    },
    lastImageUpload(file) {
      this.afterRead(file, "lastImageUpload");
    },
    // 图片上传
    async afterRead(file, key) {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("type", "shopImage");
      const { data, code } = await appMerchantApi.importUpload(formData);
      if (code == 200) {
        if (key === "loginImageUpload") {
          this.formObj.logoImage = data.url;
        }
        if (key === "firstImageUpload") {
          this.formObj.firstImage = this.imageApi + data.url;
          this.formObj.firstIcon = data.url;
        }
        if (key === "lastImageUpload") {
          this.formObj.lastImage = this.imageApi + data.url;
          this.formObj.lastIcon = data.url;
        }
        this.$forceUpdate();
      }
    },
    getUrlImg(url) {
      return this.imageApi + url;
    },
    // 发送邮件验证
    sendChange() {
      if (!this.formObj.email) {
        this.$toast.fail(this.language.visible2);
        return false;
      }
      this.sendEmailPort();
    },
    // 发送邮箱验证码
    async sendEmailPort() {
      this.timer = 59;
      let params = {
        account: this.formObj.email,
        type: 1,
      };
      const { code } = await appMerchantApi.sendEmailCode(params);
      if (code === 200) {
        this.sendOptions = this.timer + "s";
        this.sendCodeSetTimer = setInterval(() => {
          if (this.timer > 0) {
            this.timer -= 1;
            this.sendOptions = this.timer + "s";
          }
          if (this.timer <= 0) {
            this.timer = 60;
            this.sendOptions = this.language.sendEmail;
            clearInterval(this.sendCodeSetTimer);
          }
        }, 1000);
      } else {
        this.timer = 60;
      }
    },
    // 图片上传限制
    beforeRead(file) {
      let img = file.name.substring(file.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      if (!suffix && !suffix2 && !suffix3) {
        this.$toast.fail(this.language.UploadLayoutError);
        return false;
      }
      return suffix || suffix2 || suffix3;
    },
  },
};
</script>

<style lang="scss" scoped>
.appMerchant-settlement-warper {
  width: 100%;
  height: 100%;
  font-family: "GrifaSlabRegular";
  background-color: #f2f3f4;

  ::v-deep .nav-right {
    background-color: #f5f6fa;
  }

  .merchant-body {
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: scroll;
    padding: 30px;

    .body-field-boxers {
      padding: 30px 20px;
      border-radius: 18px;
      background: #fff;

      .field-row {
        margin-bottom: 30px;

        .line-label {
          margin-bottom: 20px;
          display: flex;

          .label {
            font-size: 32px;
            color: #303030;
            line-height: 1.5;
            font-weight: 600;
            display: inline-block;
          }

          i {
            font-size: 36px;
            margin-left: 8px;
            cursor: pointer;
            font-weight: 600;
            color: #6d3580;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }

        .field-boxers {
          display: flex;
          align-items: center;
        }

        ::v-deep .van-cell {
          padding: 0;
          border: 1px solid #6d3580;
          border-radius: 12px;
          padding: 0 20px;

          .van-field__body {
            .van-field__control {
              height: 84px;
              font-size: 28px;
              color: #303030;
            }

            .van-field__button {
              .send-btn {
                padding: 0 32px;
                height: 84px;
                background: #6d3580;
                color: #fff;
                border: 1px solid #6d3580;
                border-radius: 0 12px 12px 0;
              }
            }
          }
        }

        .email-field {
          padding: 0 0 0 20px;
        }
      }

      .uploader-boxers {
        display: flex;
        align-items: center;

        .upload-field {
          width: 140px;
          height: 140px;
          border: 1px dashed #6d3580;
          border-radius: 12px;
          margin-right: 15px;

          ::v-deep .van-uploader {
            width: 100%;
            height: 100%;

            .van-uploader__wrapper {
              width: 100%;
              height: 100%;

              .van-uploader__input-wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .images-load {
                  width: 100%;
                  height: 100%;
                }

                .van-icon-add-o {
                  font-size: 42px;
                  color: #6d3580 !important;
                }
              }
            }
          }
        }
      }
    }

    .body-filed-like {
      margin-top: 50px;
      display: flex;
      align-items: center;

      .like-check {
        ::v-deep .van-checkbox {
          .van-checkbox__icon {
            width: 28px !important;
            height: 28px !important;

            i {
              width: 100%;
              height: 100%;
              font-size: 22px;
              border: 1px solid #6d3580;
            }
          }

          .van-checkbox__icon--checked {
            .van-icon-success {
              color: #fff;
              background: #6d3580;
            }
          }
        }
      }

      .like-text {
        padding-left: 12px;
        display: flex;
        align-items: center;
        font-size: 28px;
        color: #6d3580;

        .like-item {
          margin-right: 20px;
          cursor: pointer;

          p {
            padding: 0 8px;
          }
        }

        .line {
          margin-top: 4px;
          width: 100%;
          height: 1px;
          background: #6d3580;
        }
      }
    }
  }

  .body-field-options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    .affirm-btn {
      width: 70%;
      padding: 20px 32px;
      font-size: 26px;
      color: #fff;
      background: #6d3580;
      border-radius: 18px;
    }
  }

  .popup-policy {
    min-height: 50%;
    max-height: 70%;
    border-radius: 18px;
    padding: 30px;

    .popup-title {
      padding: 30px;
      font-size: 42px;
      font-weight: 600;
      color: #030303;
      line-height: 1.5;
      text-align: center;
    }

    .policy-popup-body {
      height: 100%;
      overflow-y: scroll;
    }
  }
}
</style>
<style lang="scss">
.van-popover--dark {
  // left: 24px !important;

  .van-popover__action {
    width: auto;
    max-width: 400px;
    height: auto;
    padding: 15px 20px;

    .van-popover__action-text {
      font-size: 24px;
      color: #fff;
      line-height: 1.5;
    }
  }

  .van-popover__arrow {
    // left: 30% !important;
  }
}

.policy-popup-body::-webkit-scrollbar {
  display: block;
  width: 8px;
}

.policy-popup-body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ccc;
}

.policy-popup-body::-webkit-scrollbar-track {
  width: 8px;
}
</style>
